import React, { PureComponent } from 'react';

import {
  FindingArea,
  FindingContent,
  FindingTaxonomy,
  FindingSummary,
  FindingDetail,
  ActionSummary,
  ActionDetail,
  ActionRecommendation,
  ActionCaret,
} from '../atomicStyledComponents/stylesIndex';


class FindingBox extends PureComponent {
  constructor({ data }) {
    const { config, title, finding_summary, finding_detail, action_summary, action_detail, risk, organ, organ_name, condition_name, condition } = data;
    super();
    this.state = { loaded: false };
    this.title = title;
    this.organ = organ;
    this.organ_name = organ;
    this.condition = condition;
    this.condition_name = condition_name;
    this.finding_summary = finding_summary;
    this.finding_detail = finding_detail;
    this.action_summary = action_summary;
    this.action_detail = action_detail;
    this.risk = risk
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.loaded) {
      return { loaded: nextProps.loaded };
    }
    return null;
  }

  insertMarkup = (blob) => {
    return { __html: this[blob] };
  };

  render() {
    
    let imageSrc = '';let backgroundColor = 'white';
    switch (this.risk) {
      case 'low':
        imageSrc =
          'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIiBmaWxsPSIjQUQ3NjFDIj48cGF0aCBkPSJNMCAzODQuNjYyVjEyNy4zMzhjMC0xNy44MTggMjEuNTQzLTI2Ljc0MSAzNC4xNDItMTQuMTQybDEyOC42NjIgMTI4LjY2MmM3LjgxIDcuODEgNy44MSAyMC40NzQgMCAyOC4yODRMMzQuMTQyIDM5OC44MDRDMjEuNTQzIDQxMS40MDQgMCA0MDIuNDggMCAzODQuNjYyeiIvPjwvc3ZnPg==';
        backgroundColor = '#ffe48b'
        break;
      case 'medium':
        imageSrc =
          'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIiBmaWxsPSIjRjlDMTA3Ij48cGF0aCBkPSJNMCAzODQuNjYyVjEyNy4zMzhjMC0xNy44MTggMjEuNTQzLTI2Ljc0MSAzNC4xNDItMTQuMTQybDEyOC42NjIgMTI4LjY2MmM3LjgxIDcuODEgNy44MSAyMC40NzQgMCAyOC4yODRMMzQuMTQyIDM5OC44MDRDMjEuNTQzIDQxMS40MDQgMCA0MDIuNDggMCAzODQuNjYyeiIvPjwvc3ZnPg==';
        backgroundColor = '#fdaa60'
        break;
      case 'high':
        imageSrc =
          'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIiBmaWxsPSIjRTE0QjRCIj48cGF0aCBkPSJNMCAzODQuNjYyVjEyNy4zMzhjMC0xNy44MTggMjEuNTQzLTI2Ljc0MSAzNC4xNDItMTQuMTQybDEyOC42NjIgMTI4LjY2MmM3LjgxIDcuODEgNy44MSAyMC40NzQgMCAyOC4yODRMMzQuMTQyIDM5OC44MDRDMjEuNTQzIDQxMS40MDQgMCA0MDIuNDggMCAzODQuNjYyeiIvPjwvc3ZnPg==';
        backgroundColor = 'red'
        break;
      default:
        imageSrc =
          'data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiByb2xlPSJpbWciIHZpZXdCb3g9IjAgMCAxOTIgNTEyIiBmaWxsPSIjNDU4MkVDIj48cGF0aCBkPSJNMCAzODQuNjYyVjEyNy4zMzhjMC0xNy44MTggMjEuNTQzLTI2Ljc0MSAzNC4xNDItMTQuMTQybDEyOC42NjIgMTI4LjY2MmM3LjgxIDcuODEgNy44MSAyMC40NzQgMCAyOC4yODRMMzQuMTQyIDM5OC44MDRDMjEuNTQzIDQxMS40MDQgMCA0MDIuNDggMCAzODQuNjYyeiIvPjwvc3ZnPg==';
    }

    return (
      <FindingArea>
        <FindingTaxonomy>{this.condition_name}</FindingTaxonomy>
        <FindingContent backgroundColor={backgroundColor}>
          <FindingSummary dangerouslySetInnerHTML={this.insertMarkup('finding_summary')} />
          <FindingDetail dangerouslySetInnerHTML={this.insertMarkup('finding_detail')} />
          {this.action_summary && (
            <ActionRecommendation>
              {this.action_summary && (
                <ActionSummary dangerouslySetInnerHTML={this.insertMarkup('action_summary')} />
              )}
              {this.action_detail && (
                <ActionDetail dangerouslySetInnerHTML={this.insertMarkup('action_detail')} />
              )}
            </ActionRecommendation>
          )}
        </FindingContent>
      </FindingArea>
    );
  }
}

export default FindingBox;
